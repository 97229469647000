import React from 'react';

// Types
import { ImagesPropsType } from '../../types/Page';
import LazyLoadImage from 'src/components/LazyLoadImage';

import styles from './card.module.css';

const Card = ({
  alt = '',
  src = '',
  width = 0,
  height = 0,
  className = '',
  value = '',
  onClick,
  size = 'default', // xs, tiny
  ...props
}: ImagesPropsType) => {
  return (
    <LazyLoadImage
      onClick={onClick}
      alt={alt}
      src={src}
      width={width}
      height={height}
      className={className}
      {...props}
    />
    // <div className={styles["card-container"]}>
    //   <div className={styles["card-sub-wrapper"]}>
    //     <div onClick={onClick} className={`${styles["bg-card"]} ${styles[`bg-card-${size}`]} ${styles[`card-${value}`]} ${className}`} {...props} />
    //   </div>
    // </div>
  );
};

export default Card;
