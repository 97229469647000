import React, { ReactNode } from 'react';

import { Dialog } from './components/Dialog';

interface PropsModals {
  title?: string;
  className?: string;
  children: ReactNode;
  onClose?: () => void;
}
const Modals = ({
  onClose = () => {},
  title = '',
  children,
  className = ''
}: PropsModals) => {
  return (
    <Dialog onClose={onClose} className={className}>
      <h2 className="text-xl">{title}</h2>
      <div className="md:pt-0">{children}</div>
    </Dialog>
  );
};

export default Modals;
