import React, { KeyboardEvent, FocusEvent, ChangeEvent } from 'react';

import { SITE_DATA } from 'public/constants/common';

interface InputTextProps {
  placeholder?: string;
  error?: string;
  size?: 'default' | 'medium' | 'fullWidth';
  value?: string;
  className?: string;
  defaultValue?: string;
  type?: string;
  pattern?: string;
  id?: string;
  maxLength?: number;
  inputtype?: string;
  autocomplete?: string;
  readOnly?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
}

const getInputSize = size => {
  switch (size) {
    case 'medium':
      return 'w-72';
    case 'fullWidth':
      return 'w-full';
    default:
      return 'w-96';
  }
};

const isPrideCard = SITE_DATA.prideCard;

export const InputText = ({
  placeholder,
  value,
  defaultValue,
  onChange,
  onBlur,
  onKeyDown,
  className = '',
  size = 'default',
  error = '',
  readOnly = false,
  autoFocus = false,
  type = 'text',
  maxLength,
  ...props
}: InputTextProps) => (
  /*eslint-disable jsx-a11y/no-autofocus*/
  <>
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      onBlur={onBlur}
      maxLength={maxLength}
      onKeyDown={onKeyDown}
      readOnly={readOnly}
      autoFocus={autoFocus}
      className={[
        [getInputSize(size)],
        `md:p-6 py-4 pl-7 xs:p-5 text-sm rounded-lg xs:mb-2 ${className} ${
          error ? 'border border-red-500 mb-0' : ''
        }`
      ].join(' ')}
      {...props}
    />
    {error && (
      <span
        className={`${
          isPrideCard ? 'font-tertiary' : 'font-medium'
        } tracking-wide text-red-500 text-xs py-1`}
      >
        {error}
      </span>
    )}
  </>
);
