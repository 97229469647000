import React from 'react';
import Link from 'next/link';
import { ROUTES } from '../../constants/routes';

interface TextProps {
  primaryText: string;
  secondaryText?: string;
  subText?: string;
  privateText?: string;
  consent?: string;
  privacy?: string;
  size?: 'tiny' | 'small' | 'medium' | 'large' | string;
  className?: string;
  wrapperClassName?: string;
  primaryClassName?: string;
}

const getTextSize = size => {
  switch (size) {
    case 'tiny':
      return 'text-tiny';
    case 'small':
      return 'text-sm';
    case 'medium':
      return 'text-lg';
    default:
      return 'text-3xl';
  }
};

export const Text = ({
  primaryText,
  secondaryText,
  subText,
  privateText,
  consent,
  privacy,
  size,
  className = '',
  wrapperClassName = '',
  primaryClassName = '',
  ...props
}: TextProps) => (
  <p
    className={[getTextSize(size), 'inline', wrapperClassName].join(' ')}
    {...props}
  >
    <span className={[`mr-1 ${primaryClassName}`].join('')}>{primaryText}</span>
    <Link href={ROUTES.LEGAL_PAGE}>
      <span className={[`text-highlight cursor-pointer ${className}`].join('')}>
        {secondaryText}
      </span>
    </Link>

    {consent && (
      <Link href={ROUTES.LEGAL_ELECTRONIC_PAGE_LINK}>
        <span className={`mr-1 cursor-pointer ${className}`}>{consent}</span>
      </Link>
    )}
    {privacy && (
      <>
        <span className={[`mr-1 inline-block ${primaryClassName}`].join('')}>
          and
        </span>
        <Link href={ROUTES.LEGAL_PRIVACY_PAGE_LINK}>
          <a className={`mr-1 cursor-pointer ${className}`}>{privacy}</a>
        </Link>
      </>
    )}
  </p>
);
