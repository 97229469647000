import React, { ReactNode, useRef, useEffect } from 'react';

// Styles
import styles from '../Modal.module.css';

interface DialogProps {
  children: ReactNode;
  onClose?: () => void;
  className?: string;
}
export const Dialog = ({ children, onClose, className }: DialogProps) => {
  const modalDialogRef = useRef();

  const handleOutsideClick = e => {
    const targetType = e.target.getAttribute('type');
    if (targetType && targetType === 'button') {
      return;
    }
    if (e.target === modalDialogRef.current) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, false);

    return () => {
      document.removeEventListener('click', handleOutsideClick, false);
    };
  }, []);

  return (
    <div
      className={`fixed inset-0 z-50 overflow-auto flex mt-0 ${styles['bg-modal']}`}
    >
      <div
        ref={modalDialogRef}
        className={`flex m-4 md:my-8 flex items-center justify-center mx-auto ${styles['container-modal']}`}
      >
        <div
          className={`relative p-4 md:py-3 md:px-20 items-center justify-center xs:items-stretch bg-bg-modal w-full m-auto flex-col flex rounded-lg ${className}`}
        >
          <div>{children}</div>
          <span
            onClick={onClose}
            className={`absolute bg-bg-btn-close cursor-pointer rounded-full top-2 right-2 p-2 ${styles['icon-close']}`}
          ></span>
        </div>
      </div>
    </div>
  );
};
