import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { CONNECTION } from 'src/constants/common';
import { signIn, verifyOTP } from 'src/utils/api';
import { getStorage, setStorage, STORAGE_KEYS } from 'src/utils/storage';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { ERROR_MESSAGES } from 'src/constants/error';
import { useRouter } from 'next/router';
import { ROUTES } from 'src/constants/routes';
import useApp from 'src/hocs/useApp';
import useAuth from './useAuth';
import { SITE_DATA } from 'public/constants/common';

interface AuthPayloadDataType {
  accessToken: string;
  refreshToken: string;
  idToken: string;
  scope: string;
  expiresIn: number;
  tokenType: string;
}

interface SignUpDataType {
  referralCode: string;
  isVerified: string;
  userId: string;
}

interface UserMetata {
  onboardingCompleted: boolean;
  referralCode: string;
  email: string;
  phone: string;
  updatedAt: string;
  inviterInfo: {
    referralCode: string;
    email: string;
    phone: string;
    name: string;
    userId: string;
  };
}

export const useModal = (modalName: string) => {
  const userMetadata = getStorage(STORAGE_KEYS.USER_METADATA);
  const isModalVerification = modalName === 'verificationCodeModal';

  const [toggleModal, setToggle] = useState(
    isModalVerification && userMetadata ? true : false
  );
  const [processing, setProcessing] = useState(false);
  const [apiError, setApiError] = useState('');
  const router = useRouter();

  // const { isAuthenticated } = useAuth();
  const { resetData } = useApp();

  const onOpen = () => {
    document.body.classList.add('block-body-scroll');
    setToggle(true);
  };

  const onClose = () => {
    document.body.classList.remove('block-body-scroll');
    setToggle(false);
  };

  useEffect(() => {
    if (userMetadata) {
      // Navigate to Referral page
      const inviterReferralCode = getStorage(
        STORAGE_KEYS.INVITER_REFERRAL_CODE
      );
      if (
        router.asPath === ROUTES.HOME ||
        (inviterReferralCode && router.asPath.indexOf(inviterReferralCode) > -1)
      ) {
        router.push(ROUTES.REFERRAL_PAGE);
        document.body.classList.remove('block-body-scroll');
        setApiError('');
        resetData();
      }
    }
  }, [userMetadata]);

  const onProcessing = () => {
    setProcessing(true);
    setApiError('');
  };

  const onEndProcessing = () => {
    setProcessing(false);
  };

  const resetError = () => {
    setApiError('');
  };

  return {
    toggleModal,
    onOpen,
    onClose,
    processing,
    onProcessing,
    onEndProcessing,
    apiError,
    setApiError,
    resetError
  };
};
export const useSelectCardModal = () => {
  const {
    toggleModal,
    onOpen,
    onClose,
    processing,
    onProcessing,
    onEndProcessing,
    apiError,
    setApiError,
    resetError
  } = useModal('selectCardModal');

  const { logIn } = useAuth();
  const router = useRouter();

  /**
   * Resend OTP
   */
  const onResend = async (
    { connection = '', account = '' },
    callback: () => void
  ) => {
    try {
      await signIn({
        account: account.trim(),
        connection,
        // name,
        // cardType,
        inviterReferralCode: getStorage(STORAGE_KEYS.INVITER_REFERRAL_CODE)
      });
      callback();
    } catch (error) {
      console.log(error);
      onEndProcessing();
      setApiError(
        error.message.indexOf(400) > -1
          ? ERROR_MESSAGES.INVALID_ACCOUNT
          : ERROR_MESSAGES.DEFAULT
      );
    }
  };

  /**
   * Sign in / Sign up account
   * @param param0
   * @param callback
   */
  const onSubmit = async (
    { connection = '', account = '' },
    callback: () => void
  ) => {
    onProcessing();
    try {
      const response: AxiosResponse = await signIn({
        account: account.trim(),
        connection,
        // name,
        // cardType,
        inviterReferralCode: getStorage(STORAGE_KEYS.INVITER_REFERRAL_CODE)
      });
      const data: SignUpDataType = response.data;
      /**
        {
          "referralCode": "string"
          + it's returned in case user registers with email or user already exists in system.
          + when user registers with phone, he will have referral code after sign in with OTP.

          "isVerified": "boolean"
          + true: user registers with email.
          + false: user registers with phone or user already exists in system.
          OTP will be sent to email/phone to verify.

          "userId": "string"
        }
      */
      // const referralCode: string = ((data as unknown) as SignUpDataType)
      //   .referralCode;
      const isVerified: string = ((data as unknown) as SignUpDataType)
        .isVerified;

      // REGISTER NEW EMAIL
      if (connection === CONNECTION.EMAIL && isVerified) {
        // Navigate to Referral page
        await logIn({
          userId: ((data as unknown) as SignUpDataType).userId,
          userMetadata: {},
          // name,
          // cardType,
          onError: error => {
            console.log(error);
            onEndProcessing();
            setApiError(ERROR_MESSAGES.DEFAULT);
          }
        });
      } else {
        resetError();
        onEndProcessing();
        onClose();
        callback();
      }
    } catch (error) {
      console.log(error);
      onEndProcessing();
      setApiError(
        error.message.indexOf(400) > -1
          ? ERROR_MESSAGES.INVALID_ACCOUNT
          : ERROR_MESSAGES.DEFAULT
      );
    }
  };

  return {
    toggleModal,
    onOpen,
    onClose,
    processing,
    onProcessing,
    onEndProcessing,
    onSubmit,
    onResend,
    apiError,
    setApiError
  };
};

export const useVerificationCodeModal = () => {
  const {
    toggleModal,
    onOpen,
    onClose,
    processing,
    onProcessing,
    onEndProcessing,
    apiError,
    setApiError
  } = useModal('verificationCodeModal');

  const { connection } = useApp();
  const { logIn } = useAuth();
  const router = useRouter();

  /**
   * Verify OTP
   * @param code
   */
  const onSubmit = async (code: string) => {
    // const cardType = selectedCard;
    const account = getStorage(STORAGE_KEYS.ACCOUNT);
    // const userName = getStorage(STORAGE_KEYS.USER_NAME);
    onProcessing();
    try {
      const response: AxiosResponse = await verifyOTP({
        account: account.trim(),
        connection,
        otp: code,
        referralCode: getStorage(STORAGE_KEYS.INVITER_REFERRAL_CODE)
        // name: userName,
        // cardType: cardType
      });
      const data: AuthPayloadDataType = response.data;
      const token: string = ((data as unknown) as AuthPayloadDataType).idToken;
      const decoded: any = jwtDecode<JwtPayload>(token);
      const userMetadata = decoded[
        `${SITE_DATA.domain}user_metadata`
      ] as UserMetata;

      setStorage(STORAGE_KEYS.AUTH_TOKEN, token);

      await logIn({
        userId: decoded.sub,
        userMetadata: userMetadata,
        // name: userName,
        // cardType,
        onError: error => {
          console.log(error);
          onEndProcessing();
          setApiError(ERROR_MESSAGES.DEFAULT);
        }
      });
    } catch (error) {
      console.log(error);
      onEndProcessing();
      setApiError(ERROR_MESSAGES.INVALID_VERIFICATION_CODE);
    }
  };

  return {
    toggleModal,
    onOpen,
    onClose,
    processing,
    onProcessing,
    onEndProcessing,
    onSubmit,
    apiError,
    setApiError
  };
};

export const useUpdateEmailModal = () => {
  const {
    toggleModal,
    onOpen,
    onClose,
    processing,
    onProcessing,
    onEndProcessing,
    apiError,
    setApiError
    // resetError
  } = useModal('updateEmailModal');

  const { linkAccount } = useAuth();

  const onSubmit = async (email: string, callback: () => void) => {
    onProcessing();
    try {
      await linkAccount({
        email,
        onError: error => {
          console.log(error);
          onEndProcessing();
          setApiError(
            error.message.indexOf(400) > -1
              ? ERROR_MESSAGES.EMAIL_ALREADY_CONNECTED
              : ERROR_MESSAGES.DEFAULT
          );
        },
        onSuccess: () => {
          onEndProcessing();
          onClose();
          callback();
        }
      });
    } catch (error) {
      console.log(error);
      onEndProcessing();
      setApiError(ERROR_MESSAGES.DEFAULT);
    }
  };

  return {
    toggleModal,
    onOpen,
    onClose,
    processing,
    onProcessing,
    onEndProcessing,
    onSubmit,
    apiError,
    setApiError
  };
};
