import { SITE_DATA } from './common';

export const HOMEPAGE_MAIN_DATA = {
  title: 'PawsCard - Get the official PawsCard',
  description:
    'Get 1% back on every purchase: Donate to charity or get cash back.'
};

export const NOTFOUND_MAIN_DATA = {
  title: 'PawsCard - Not Found Page',
  description:
    'Get 1% back on every purchase: Donate to charity or get cash back.'
};

export const HOMEPAGE_METADATA_WITH_REFERRAL = {
  description:
    'Get 1% back on every purchase: Donate to charity or get cash back.',
  ogDescription:
    'Get 1% back on every purchase: Donate to charity or get cash back.',
  ogImageWidth: '1200',
  ogImageHeight: '628',
  ogTitle: 'PawsCard - Get the official PawsCard',
  ogUrl: inviterReferralCode => `${SITE_DATA.domain}${inviterReferralCode}`,
  themeColor: '#5cd95b',
  ogImage: `${process.env.NEXT_PUBLIC_DOMAIN}logos/home-preview.png`,
  twitterTitle: inviterReferralCode => `PawsCard - Get the PawsCard with
  my link: ${SITE_DATA.domain}${inviterReferralCode}`
};

export const HOMEPAGE_METADATA_WITHOUT_REFERRAL = {
  ogDescription:
    'Get 1% back on every purchase: Donate to charity or get cash back.',
  ogImageWidth: '1200',
  ogImageHeight: '628',
  ogTitle: 'PawsCard - Get the PawsCard',
  ogUrl: inviterReferralCode => SITE_DATA.domain,
  themeColor: '#5cd95b',
  ogImage: `${process.env.NEXT_PUBLIC_DOMAIN}logos/home-preview.png`,
  twitterTitle: inviterReferralCode => `PawsCard - Get the PawsCard`
};

export const REFERRAL_PAGE_MAIN_DATA = {
  title: 'PawsCard - Send to friends',
  description: 'Send PawsCard to your friends and earn rewards.'
};

export const LEGAL_PAGE_MAIN_DATA = {
  title: 'PawsCard Terms of Service',
  description:
    'This page explains our terms for PawsCard. By using the service you agree to these terms.'
};

export const LEGAL_PRIVACY_PAGE_MAIN_DATA = {
  title: 'PawsCard Privacy Policy',
  description:
    'This page explains our terms for PawsCard. By using the service you agree to these terms.'
};

export const LEGAL_REWARDS_PAGE_MAIN_DATA = {
  title: 'PawsCard Rewards',
  description:
    'This page explains our terms for PawsCard. By using the service you agree to these terms.'
};

export const FACEBOOK_SHARE_INFO = {
  title: (referralLink, rewardAmount) =>
    `Get a $${rewardAmount} bonus by signing up with my link ${referralLink}`,
  hashtag: 'PawsCard'
};

export const TWITTER_SHARE_INFO = {
  title: rewardAmount =>
    `Get a $${rewardAmount} bonus by signing up with my link `,
  hashtag: ['PawsCard']
};

export const WHATSAPP_SHARE_INFO = {
  title: (referralLink, rewardAmount) =>
    `Get a $${rewardAmount} bonus by signing up with my link ${referralLink}`,
  hashtag: 'PawsCard, invitefriends'
};

export const EMAIL_SHARE_INFO = {
  subject: 'PawsCard invite',
  body: referralLink =>
    `I just signed up for PawsCard, a Debit card that gives you Causecoin back on every purchase. Signup with my link ${referralLink} and we both get +5 Shibcoins.`
};
